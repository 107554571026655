import React, { ReactElement } from "react";
import TextareaForm from "../../../../shared/form/textarea_form";

export default function AdviceRequestForm(): ReactElement {
  return (
    <React.Fragment>
      <div className="font-weight-bold text-center mt-4 font__size-16">
        ウェルビーイングについての質問や相談はありますか？
      </div>
      <div className="text-center mt-1 mb-2 font__size-14 color__gray">
        記載頂いた場合は、ハッピーAIがお答えします
      </div>
      <TextareaForm
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        scope="questionnaire"
        columnName="advice_request_body"
        index={0}
        hideLabel
      />
    </React.Fragment>
  );
}
