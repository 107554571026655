import React, { ReactElement } from "react";
import { ContractPeriodResponse } from "../../../models/ContractPeriod";
import { DepartmentResponse } from "../../../models/Department";
import Button from "../../shared/button";

interface Props {
  department: DepartmentResponse;
  contractPeriod: ContractPeriodResponse;
  isCurrentAdvice: boolean;
}

export default function ChatHistoryItem(props: Props): ReactElement {
  const { department, contractPeriod, isCurrentAdvice } = props;

  return (
    <div className="mt-2" style={{}}>
      <Button
        className={isCurrentAdvice ? "bgcolor__trivial" : "cursor__pointer"}
        disabled={isCurrentAdvice}
        onClick={() => {
          window.location.href = `/user/departments/${department.id}/contract_periods/${contractPeriod.id}/contract_period_department_advices`;
        }}
      >
        <div className="border__lightgray rounded font__size-13 p-2">
          {department.variety === "whole" && (
            <div className="color__gray">
              {contractPeriod.view_period_with_department_name}
            </div>
          )}
          {department.variety !== "whole" && (
            <div className="color__gray">{contractPeriod.view_period}</div>
          )}
        </div>
      </Button>
    </div>
  );
}
