import React, { ReactElement } from "react";
import { InteractiveHappinessAdviceChatResponse } from "../../../models/InteractiveHappinessAdviceChat";
import HappyBirdImage from "../../shared/images/happy_bird.png";

interface Props {
  interactiveHappinessAdviceChats: InteractiveHappinessAdviceChatResponse[];
}

const checkKeyword = (text: string): boolean => {
  const keywords = ["自殺", "死", "絶望"];
  for (let i = 0; i < keywords.length; i++) {
    const keyword = keywords[i];
    if (text.includes(keyword)) {
      return true;
    }
  }
  return false;
};

export default function LastChat(props: Props): ReactElement {
  const { interactiveHappinessAdviceChats } = props;

  const userChat = interactiveHappinessAdviceChats.filter(
    (ihac) => ihac.role === "user_role"
  )[0];

  if (userChat === undefined) {
    return <></>;
  }

  if (checkKeyword(userChat.message)) {
    return (
      <div className="chat-screen__chat-message-container">
        <div className="d-flex justify-content-start">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
        </div>
        <div className="d-flex justify-content-start px-3 pb-3 mt-1">
          <div
            className="p-3 bgcolor__trivial rounded"
            style={{ maxWidth: "80%" }}
          >
            もしあなたが先の見えない不安や、生きづらさを感じるなどの様々なこころの悩みを抱えていたら、その悩みを相談することができる機関があります。
            <br />
            必要があればご利用ください。
            <br />
            <div className="d-flex justify-content-end align-items-center mt-3">
              <div className="mx-2">
                <div>
                  <a
                    href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/hukushi_kaigo/seikatsuhogo/jisatsu/soudan_info.html"
                    target="_blank"
                  >
                    リンク: 相談先一覧｜自殺対策｜厚生労働省
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
