import React, { ReactElement } from "react";
import { EventResponse } from "../../../../../../models/Event";
import { IndustryCategoryWithIndustriesResponse } from "../../../../../../models/IndustryCategoryApi";
import { QuestionnaireNewResponse } from "../../../../../../models/QuestionnaireApi";
import { QuestionnaireNewUserResponse } from "../../../../../../models/UserApi";
import { UserProfileMyselfResponse } from "../../../../../../models/UserProfile";
import { UserSettingResponse } from "../../../../../../models/UserSetting";
import CheckboxForm from "../../../../../shared/form/checkbox_form";
import SelectForm from "../../../../../shared/form/select_form";
import TextForm from "../../../../../shared/form/text_form";
import IndustryForm from "./user_profile_form/industry_form";
import LivingAreaForm from "./user_profile_form/living_area_form";
import RelatedIndicatorsForm from "./user_profile_form/related_indicators_form";

const isJaLocale = window.I18n.locale === "ja";

interface Props {
  isConsumer: boolean;
  user: QuestionnaireNewUserResponse | null;
  questionnaire: QuestionnaireNewResponse;
  isProfileInputAvailable: boolean;
  userProfile: UserProfileMyselfResponse | null;
  birthYearOptions: Array<{ key: string; value: string }>;
  birthMonthOptions: Array<{ key: string; value: string }>;
  sexOptions: Array<{ key: string; value: string }>;
  finalEducationOptions: Array<{ key: string; value: string }>;
  professionOptions: Array<{ key: string; value: string }>;
  personalAnnualIncomeOptions: Array<{ key: string; value: string }>;
  householdAnnualIncomeOptions: Array<{ key: string; value: string }>;
  maritalStatusOptions: Array<{ key: string; value: string }>;
  childStatusOptions: Array<{ key: string; value: string }>;
  prefectureOptions: Array<{ key: string; value: string }>;
  countryOptions: Array<{ key: string; value: string }>;
  event: EventResponse | null;
  isLoggedIn: boolean;
  userSetting: UserSettingResponse;
  industryCategories: IndustryCategoryWithIndustriesResponse[];
}

export default function UserProfileForm(props: Props): ReactElement {
  const {
    isConsumer,
    user,
    questionnaire,
    isProfileInputAvailable,
    userProfile,
    birthYearOptions,
    birthMonthOptions,
    sexOptions,
    finalEducationOptions,
    professionOptions,
    personalAnnualIncomeOptions,
    householdAnnualIncomeOptions,
    maritalStatusOptions,
    childStatusOptions,
    prefectureOptions,
    countryOptions,
    event,
    isLoggedIn,
    userSetting,
    industryCategories,
  } = props;

  const createOptionalFormArr = () => {
    const optionalFormArr: ReactElement[] = [];
    if (
      isConsumer &&
      (event === null ||
        event.profile_input_for_research !== "hide_profile_input_for_research")
    ) {
      optionalFormArr.push(
        <RelatedIndicatorsForm
          key="related_indicators"
          questionnaire={questionnaire}
        />
      );
    }
    if (
      !(
        isLoggedIn &&
        userProfile?.final_education !== undefined &&
        userProfile.final_education !== null
      )
    ) {
      optionalFormArr.push(
        <SelectForm
          key="final_education"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="final_education"
          options={finalEducationOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      !(
        isLoggedIn &&
        userProfile?.profession !== undefined &&
        userProfile.profession !== null
      )
    ) {
      optionalFormArr.push(
        <SelectForm
          key="profession"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="profession"
          options={professionOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      isJaLocale &&
      !(
        isLoggedIn &&
        userProfile?.personal_annual_income !== undefined &&
        userProfile.personal_annual_income !== null
      )
    ) {
      optionalFormArr.push(
        <SelectForm
          key="personal_annual_income"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="personal_annual_income"
          options={personalAnnualIncomeOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      isJaLocale &&
      !(
        isLoggedIn &&
        userProfile?.household_annual_income !== undefined &&
        userProfile.household_annual_income !== null
      )
    ) {
      optionalFormArr.push(
        <SelectForm
          key="household_annual_income"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="household_annual_income"
          options={householdAnnualIncomeOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      !(
        isLoggedIn &&
        userProfile?.marital_status !== undefined &&
        userProfile.marital_status !== null
      )
    ) {
      optionalFormArr.push(
        <SelectForm
          key="marital_status"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="marital_status"
          options={maritalStatusOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      !(
        isLoggedIn &&
        userProfile?.child_status !== undefined &&
        userProfile.child_status !== null
      )
    ) {
      optionalFormArr.push(
        <SelectForm
          key="child_status"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="child_status"
          options={childStatusOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      isJaLocale &&
      !(
        isLoggedIn &&
        userProfile?.prefecture_code !== undefined &&
        userProfile.prefecture_code !== null &&
        userProfile.city_id !== null
      )
    ) {
      optionalFormArr.push(
        <LivingAreaForm
          key="living_area"
          userProfile={userProfile}
          prefectureOptions={prefectureOptions}
        />
      );
    }
    if (
      isJaLocale &&
      !(
        isLoggedIn &&
        userProfile?.industry_category_id !== undefined &&
        userProfile.industry_category_id !== null &&
        userProfile.industry_id !== null
      )
    ) {
      optionalFormArr.push(
        <IndustryForm
          key="industry"
          userProfile={userProfile}
          industryCategories={industryCategories}
        />
      );
    }
    return optionalFormArr;
  };

  const createArbitraryProfileFormArr = (
    canShowSexForm,
    isSexRequired,
    canShowBirthYearForm,
    isBirthYearRequired,
    canShowBirthMonthForm,
    isBirthMonthRequired
  ) => {
    const arr: ReactElement[] = [];
    if (
      !(
        isLoggedIn &&
        userProfile?.sex !== undefined &&
        userProfile.sex !== null
      ) &&
      canShowSexForm &&
      !isSexRequired
    ) {
      arr.push(
        <SelectForm
          key="sex"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="sex"
          options={sexOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      !(
        isLoggedIn &&
        userProfile?.birth_year !== undefined &&
        userProfile.birth_year !== null
      ) &&
      canShowBirthYearForm &&
      !isBirthYearRequired
    ) {
      arr.push(
        <SelectForm
          key="birth_year"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="birth_year"
          options={birthYearOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      !(
        isLoggedIn &&
        userProfile?.birth_month !== undefined &&
        userProfile.birth_month !== null
      ) &&
      canShowBirthMonthForm &&
      !isBirthMonthRequired
    ) {
      arr.push(
        <SelectForm
          key="birth_month"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="birth_month"
          options={birthMonthOptions}
          option={{
            include_blank: window.I18n.t(
              "javascript.questionnaires.happiness_questionnaire.unselected"
            ),
          }}
        />
      );
    }
    if (
      isConsumer &&
      !(
        isLoggedIn &&
        userProfile?.company_name !== undefined &&
        userProfile.company_name !== ""
      ) &&
      isProfileInputAvailable
    ) {
      arr.push(
        <TextForm
          key="company_name"
          scope="questionnaire"
          model={userProfile}
          relatedModelName="user_profile"
          columnName="company_name"
        />
      );
    }
    return arr;
  };

  const sexFormOption = () => {
    let isRequired = false;
    if (user === null || user.is_consumer) {
      isRequired =
        event !== null
          ? event.profile_input_sex === "require_profile_input_sex"
          : true;
    } else {
      isRequired =
        user.profile_input_sex === "require_profile_input_sex" ||
        event?.profile_input_sex === "require_profile_input_sex";
    }
    let canShowForm = isRequired;
    if (!canShowForm) {
      canShowForm =
        event !== null
          ? event.profile_input_sex !== "hide_profile_input_sex"
          : user?.profile_input_sex !== "hide_profile_input_sex";
    }
    return [isRequired, canShowForm];
  };

  const birthYearFormOption = () => {
    let isRequired = false;
    if (user === null || user.is_consumer) {
      isRequired =
        event !== null
          ? event.profile_input_birth_year ===
            "require_profile_input_birth_year"
          : true;
    } else {
      isRequired =
        user.profile_input_birth_year === "require_profile_input_birth_year" ||
        event?.profile_input_birth_year === "require_profile_input_birth_year";
    }
    let canShowForm = isRequired;
    if (!canShowForm) {
      canShowForm =
        event !== null
          ? event.profile_input_birth_year !== "hide_profile_input_birth_year"
          : user?.profile_input_birth_year !== "hide_profile_input_birth_year";
    }
    return [isRequired, canShowForm];
  };

  const birthMonthFormOption = () => {
    let isRequired = false;
    if (user === null || user.is_consumer) {
      isRequired =
        event !== null
          ? event.profile_input_birth_month ===
            "require_profile_input_birth_month"
          : true;
    } else {
      isRequired =
        user.profile_input_birth_month ===
          "require_profile_input_birth_month" ||
        event?.profile_input_birth_month ===
          "require_profile_input_birth_month";
    }
    let canShowForm = isRequired;
    if (!canShowForm) {
      canShowForm =
        event !== null
          ? event.profile_input_birth_month !== "hide_profile_input_birth_month"
          : user?.profile_input_birth_month !==
            "hide_profile_input_birth_month";
    }
    return [isRequired, canShowForm];
  };

  const [isSexRequired, canShowSexForm] = sexFormOption();
  const [isBirthYearRequired, canShowBirthYearForm] = birthYearFormOption();
  const [isBirthMonthRequired, canShowBirthMonthForm] = birthMonthFormOption();

  // 研究用項目
  const optionalFormArr = createOptionalFormArr();
  const optionalForms = () => (
    <React.Fragment>
      {optionalFormArr.length > 0 && (
        <div className="happiness-questionnaire__user-profile-notification-container">
          {window.I18n.t(
            "javascript.questionnaires.happiness_questionnaire.profile_usage_message"
          )}
        </div>
      )}
      {optionalFormArr}
      {optionalFormArr.length > 0 && (
        <CheckboxForm
          title={window.I18n.t(
            "javascript.questionnaires.happiness_questionnaire.profile_form_hide"
          )}
          scope="questionnaire"
          relatedModelName="user_setting"
          columnName="hide_profile_form_in_questionnaire"
          defaultChecked={userSetting.hide_profile_form_in_questionnaire}
          style={{ textAlign: "center" }}
        />
      )}
    </React.Fragment>
  );

  // 任意プロフィール項目
  const arbitraryProfileFormArr = createArbitraryProfileFormArr(
    canShowSexForm,
    isSexRequired,
    canShowBirthYearForm,
    isBirthYearRequired,
    canShowBirthMonthForm,
    isBirthMonthRequired
  );

  return (
    <React.Fragment key="userFormBox">
      {!(
        isLoggedIn &&
        userProfile?.sex !== undefined &&
        userProfile.sex !== null
      ) &&
        canShowSexForm &&
        isSexRequired && (
          <SelectForm
            key="sex"
            scope="questionnaire"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="sex"
            options={sexOptions}
            required
            option={{
              include_blank: window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.unselected"
              ),
            }}
          />
        )}
      {!(
        isLoggedIn &&
        userProfile?.birth_year !== undefined &&
        userProfile.birth_year !== null
      ) &&
        canShowBirthYearForm &&
        isBirthYearRequired && (
          <SelectForm
            key="birth_year"
            scope="questionnaire"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="birth_year"
            options={birthYearOptions}
            required
            option={{
              include_blank: window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.unselected"
              ),
            }}
          />
        )}
      {!(
        isLoggedIn &&
        userProfile?.birth_month !== undefined &&
        userProfile.birth_month !== null
      ) &&
        canShowBirthMonthForm &&
        isBirthMonthRequired && (
          <SelectForm
            key="birth_month"
            scope="questionnaire"
            model={userProfile}
            relatedModelName="user_profile"
            columnName="birth_month"
            options={birthMonthOptions}
            required
            option={{
              include_blank: window.I18n.t(
                "javascript.questionnaires.happiness_questionnaire.unselected"
              ),
            }}
          />
        )}
      {!(isLoggedIn && userSetting.hide_profile_form_in_questionnaire) && (
        <React.Fragment>
          {!isLoggedIn && arbitraryProfileFormArr.length > 0 && (
            <div className="text-center">
              <span className="font__size-13 font-weight-bold">
                {window.I18n.t(
                  "javascript.questionnaires.happiness_questionnaire.profile_input_note"
                )}
              </span>
            </div>
          )}
          {arbitraryProfileFormArr}
          {!isJaLocale &&
            !(
              isLoggedIn &&
              userProfile?.country_code !== undefined &&
              userProfile.country_code !== null
            ) && (
              <SelectForm
                key="country_code"
                scope="questionnaire"
                model={userProfile}
                relatedModelName="user_profile"
                columnName="country_code"
                options={countryOptions}
                option={{
                  include_blank: window.I18n.t(
                    "javascript.questionnaires.happiness_questionnaire.unselected"
                  ),
                }}
              />
            )}
          {isProfileInputAvailable && optionalForms()}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
