import React, { ReactElement, useEffect, useState } from "react";
import { CookiesProvider } from "react-cookie";
import { QuestionnaireNewResponse } from "../../models/QuestionnaireApi";
import {
  checkConsistency,
  getQuestionnaireForm,
  removeQuestionnaireForm,
} from "../../utils/strage_utils";
import HiddenForm from "../shared/form/hidden_form";
import LoadingImage from "../shared/images/spin-gray.svg";
import EventTitle from "./happiness_questionnaire/event_title";
import HappinessQuestionnaireForm from "./happiness_questionnaire/happiness_questionnaire_form";
import QuestionnaireSelectForm from "./happiness_questionnaire/questionnaire_select_form";
import { Props } from "./shared/HappinessQuestionnareType";

type ReuseModeType = "notReuse" | "reuseAll";

function mergeLocalStorageHappinessAnswers(
  originalQuestionnaire: QuestionnaireNewResponse,
  formInformation: any
): QuestionnaireNewResponse {
  return Object.assign({}, originalQuestionnaire, {
    happiness_answers: originalQuestionnaire.happiness_answers.map((ha) => {
      if (ha.point > 0) {
        return ha;
      }
      const hqElement = formInformation.elements.filter(
        (element) =>
          element.name.includes("happiness_question_id") &&
          element.value === String(ha.happiness_question.id)
      )[0];
      if (!hqElement) {
        return ha;
      }
      const haPointName = hqElement.name.replace(
        "happiness_question_id",
        "point"
      );
      const haPointElement = formInformation.elements.filter(
        (element) => element.name === haPointName
      )[0];
      if (!haPointElement || haPointElement.value === "0") {
        return ha;
      }
      return Object.assign({}, ha, { point: Number(haPointElement.value) });
    }),
  });
}

export default function HappinessQuestionnaire(props: Props): ReactElement {
  const {
    isConsumer,
    questionnaire: propQuestionnaire,
    headerAreaIcon,
    user,
    isProfileInputAvailable,
    userProfile,
    birthYearOptions,
    birthMonthOptions,
    sexOptions,
    finalEducationOptions,
    professionOptions,
    personalAnnualIncomeOptions,
    householdAnnualIncomeOptions,
    maritalStatusOptions,
    childStatusOptions,
    prefectureOptions,
    countryOptions,
    previousQuestionnaire,
    event,
    eventAnswerTiming,
    userIdentifierToken,
    isLoggedIn,
    // selectedQuestionnaireMethod,
    selfIntroductionQuestions,
    selfIntroductionAnswers,
    departmentOptions,
    managerialPositionOptions,
    members,
    departments,
    userSetting,
    isDevelopmentRequestFormVisible,
    isAdviceRequestFormVisible,
    errorMessages,
    industryCategories,
  } = props;

  const referenceQuestionnaireUsageAlreadySelected = [
    "all_happiness_answers_recycled",
    "partial_happiness_answers_recycled",
  ].includes(propQuestionnaire.reference_questionnaire_usage);
  const reusable =
    event !== null &&
    previousQuestionnaire !== null &&
    propQuestionnaire.survey_type !== "work_happiness_survey";
  let initialReuseMode: ReuseModeType | undefined = "notReuse";
  if (referenceQuestionnaireUsageAlreadySelected) {
    initialReuseMode = "reuseAll";
  } else if (reusable) {
    initialReuseMode = undefined;
  }
  const [preparing, setPreparing] = useState(true);
  const [questionnaire, setQuestionnaire] = useState(propQuestionnaire);
  const [reuseMode, setReuseMode] = useState<undefined | ReuseModeType>(
    initialReuseMode
  );

  useEffect(() => {
    if (!checkConsistency(event, eventAnswerTiming)) {
      removeQuestionnaireForm();
    }
    const tempQuestionnaireForm = getQuestionnaireForm();
    if (!tempQuestionnaireForm) {
      setPreparing(false);
      return;
    }
    const q = mergeLocalStorageHappinessAnswers(
      questionnaire,
      tempQuestionnaireForm
    );
    setQuestionnaire(q);
    setPreparing(false);
  }, []);

  if (preparing) {
    return (
      <div className="relative mt-2">
        <img
          src={LoadingImage}
          style={{
            width: 50,
            height: 50,
            position: "absolute",
            top: "calc(50% + 80px)",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    );
  }

  return (
    <CookiesProvider>
      <>
        <input
          type="hidden"
          name="questionnaire[survey_type]"
          value={questionnaire.survey_type}
        />
        {userIdentifierToken !== null && (
          <HiddenForm
            scope="questionnaire"
            columnName="user_identifier_token"
            value={userIdentifierToken}
          />
        )}
        <input
          type="hidden"
          name="questionnaire[selected_questionnaire_method]"
          value="full_questionnaire"
        />
        {previousQuestionnaire !== null && (
          <input
            id="questionnaire_reference_questionnaire_id"
            name="questionnaire[reference_questionnaire_id]"
            type="hidden"
            value={previousQuestionnaire.id}
          />
        )}
        {reuseMode !== undefined && (
          <input
            id="questionnaire_reference_questionnaire_usage"
            name="questionnaire[reference_questionnaire_usage]"
            type="hidden"
            value={
              reuseMode === "notReuse"
                ? "reference_questionnaire_unused"
                : "all_happiness_answers_recycled"
            }
          />
        )}
        {event !== null && (
          <EventTitle event={event} eventAnswerTiming={eventAnswerTiming} />
        )}
        {reuseMode === undefined && reusable && (
          <QuestionnaireSelectForm
            questionnaire={questionnaire}
            previousQuestionnaire={previousQuestionnaire}
            onSelectReuseMode={(mode, q) => {
              setQuestionnaire(q);
              setReuseMode(mode);
            }}
          />
        )}
        {reuseMode !== undefined && (
          <HappinessQuestionnaireForm
            isReused={reuseMode === "reuseAll"}
            questionnaire={questionnaire}
            previousQuestionnaire={previousQuestionnaire}
            headerAreaIcon={headerAreaIcon}
            user={user}
            event={event}
            errorMessages={errorMessages}
            isConsumer={isConsumer}
            isProfileInputAvailable={isProfileInputAvailable}
            userProfile={userProfile}
            birthYearOptions={birthYearOptions}
            birthMonthOptions={birthMonthOptions}
            sexOptions={sexOptions}
            finalEducationOptions={finalEducationOptions}
            professionOptions={professionOptions}
            personalAnnualIncomeOptions={personalAnnualIncomeOptions}
            householdAnnualIncomeOptions={householdAnnualIncomeOptions}
            maritalStatusOptions={maritalStatusOptions}
            childStatusOptions={childStatusOptions}
            prefectureOptions={prefectureOptions}
            countryOptions={countryOptions}
            isLoggedIn={isLoggedIn}
            selfIntroductionQuestions={selfIntroductionQuestions}
            selfIntroductionAnswers={selfIntroductionAnswers}
            departmentOptions={departmentOptions}
            managerialPositionOptions={managerialPositionOptions}
            members={members}
            departments={departments}
            userSetting={userSetting}
            isDevelopmentRequestFormVisible={isDevelopmentRequestFormVisible}
            isAdviceRequestFormVisible={isAdviceRequestFormVisible}
            industryCategories={industryCategories}
          />
        )}
      </>
    </CookiesProvider>
  );
}
