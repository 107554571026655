import React, { ReactElement } from "react";
import { ContractPeriodResponse } from "../../../models/ContractPeriod";
import { DepartmentResponse } from "../../../models/Department";
import Button from "../../shared/button";
import ChatHistoryItem from "./chat_history_item";

interface Props {
  department: DepartmentResponse;
  contractPeriod: ContractPeriodResponse;
  contractPeriods: ContractPeriodResponse[];
}

export default function ChatScreenMenuArea(props: Props): ReactElement {
  const {
    department,
    contractPeriod: currentContractPeriod,
    contractPeriods,
  } = props;

  return (
    <div className="chat-screen__menu-container">
      <div className="chat-screen__menu-top-container-only-mobile">
        <div>
          <Button
            title="過去のメッセージ・アドバイス"
            className="btn button__light chat-screen__interactive-work-history-button"
            onClick={() => {
              window.location.href = `/user/departments/${department.id}/contract_periods/${currentContractPeriod.id}/contract_period_department_advices/histories`;
            }}
          />
        </div>
      </div>
      <div className="chat-screen__menu-history-container">
        <div className="mt-3">
          <a
            href={`/user/departments/${department.id}/overview?period=${currentContractPeriod.id}`}
            className="font__size-14"
          >
            {"< 幸福度詳細に戻る"}
          </a>
        </div>
        <div className="mt-3 text-center font-weight-bold">
          {department.name}
        </div>
        <div className="font__size-13 color__gray mt-3 text-center">
          過去のメッセージ・アドバイス
        </div>
        {contractPeriods.map((cp) => {
          return (
            <ChatHistoryItem
              key={cp.id}
              department={department}
              contractPeriod={cp}
              isCurrentAdvice={currentContractPeriod.id === cp.id}
            />
          );
        })}
      </div>
    </div>
  );
}
