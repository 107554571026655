import { faClipboard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ReactElement, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { ContractPeriodResponse } from "../../../models/ContractPeriod";
import { ContractPeriodDepartmentAdviceResponse } from "../../../models/ContractPeriodDepartmentAdvice";
import { DepartmentResponse } from "../../../models/Department";
import { getRequestAsync } from "../../../utils/api_client";
import { formatForMarkdown } from "../../../utils/markdown_formatter";
import Button from "../../shared/button";
import HappyBirdImage from "../../shared/images/happy_bird.png";

interface Props {
  department: DepartmentResponse;
  contractPeriod: ContractPeriodResponse;
  contractPeriodDepartmentAdvice: ContractPeriodDepartmentAdviceResponse;
  title: string;
}

export default function Chat(props: Props): ReactElement {
  const {
    department,
    contractPeriod,
    contractPeriodDepartmentAdvice: initialContractPeriodDepartmentAdvice,
    title,
  } = props;
  const [loading, setLoading] = useState(false);
  const [contractPeriodDepartmentAdvice, setContractPeriodDepartmentAdvice] =
    useState(initialContractPeriodDepartmentAdvice);

  const reload = async () => {
    setLoading(true);
    const { result } =
      await getRequestAsync<ContractPeriodDepartmentAdviceResponse>(
        `/user/departments/${department.id}/contract_periods/${contractPeriod.id}/contract_period_department_advices/${contractPeriodDepartmentAdvice.id}`
      );
    if (result !== undefined) {
      setContractPeriodDepartmentAdvice(result);
    }
    setLoading(false);
  };

  return (
    <div className="chat-screen__chat-message-container">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-start align-items-center">
          <div className="ml-3">
            <img src={HappyBirdImage} style={{ width: 28 }} />
          </div>
          <div className="ml-2 font-weight-bold">{title}</div>
        </div>
        <div className="mr-3">
          {contractPeriodDepartmentAdvice.state === "activated" && (
            <CopyToClipboard text={contractPeriodDepartmentAdvice.body}>
              <button className="btn button__light-slim">
                <div className="d-flex justify-content-center align-items-center">
                  <FontAwesomeIcon
                    icon={faClipboard}
                    className="font__size-13 color__gray"
                  />
                  <div className="ml-2">Copy</div>
                </div>
              </button>
            </CopyToClipboard>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-start px-3 pb-3 mt-1">
        <div
          className="p-3 bgcolor__trivial rounded markdown__container"
          style={{ width: "100%" }}
        >
          {contractPeriodDepartmentAdvice.state === "preparing" && (
            <div>
              <div className="color__gray text-center">
                メッセージを生成中です。
                <br />
                約1分後にメッセージを読み込んでください
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Button
                  title="再読み込み"
                  className="btn button__light"
                  onClick={reload}
                  loading={loading}
                />
              </div>
            </div>
          )}
          {contractPeriodDepartmentAdvice.state === "disabled" && (
            <div className="color__gray text-center">
              申し訳ありません。
              <br />
              回答数が少ないためメッセージを作成できませんでした。
            </div>
          )}
          {contractPeriodDepartmentAdvice.state === "activated" &&
            contractPeriodDepartmentAdvice.body !== null && (
              <ReactMarkdown
                children={formatForMarkdown(
                  contractPeriodDepartmentAdvice.body
                )}
                remarkPlugins={[remarkGfm]}
              />
            )}
        </div>
      </div>
    </div>
  );
}
