import React, { ReactElement, useEffect, useState } from "react";
import Scroll from "react-scroll";
import { QuestionnaireResponse } from "../../models/Questionnaire";
import { QuestionnaireAdviceResponse } from "../../models/QuestionnaireAdvice";
import BaseModal from "../shared/base_modal";
import Button from "../shared/button";
import Chat from "./chat_screen/chat";
import ChatInProgress from "./chat_screen/chat_in_progress";
import ChatScreenMenuArea from "./chat_screen/chat_screen_menu_area";

interface Props {
  questionnaire: QuestionnaireResponse;
  questionnaires: QuestionnaireResponse[];
  praiseRoleQuestionnaireAdvice: QuestionnaireAdviceResponse;
  adviceRoleQuestionnaireAdvice: QuestionnaireAdviceResponse;
  highHappinessElements: string[];
  lowHappinessElements: string[];
  raisedHappinessElements: string[];
  lambdaFunctionUrlDomain: string;
  awsAccessRoleName: string;
  interactiveHappinessAdvicePath: string;
  interactiveWorkPath: string;
  scoreList: Array<{
    happiness_category: string;
    happiness_element: string;
    diff_score_from_general_average: number;
  }>;
}

const scroll = Scroll.animateScroll;

function scrollToBottomOfChatContent(): void {
  scroll.scrollToBottom({
    containerId: "chatContentArea",
    duration: 100,
  });
}

export default function ChatScreen(props: Props): ReactElement {
  const {
    questionnaire,
    questionnaires,
    praiseRoleQuestionnaireAdvice: initialPraiseRoleQuestionnaireAdvice,
    adviceRoleQuestionnaireAdvice: initialAdviceRoleQuestionnaireAdvice,
    highHappinessElements,
    lowHappinessElements,
    raisedHappinessElements,
    lambdaFunctionUrlDomain,
    awsAccessRoleName,
    interactiveHappinessAdvicePath,
    interactiveWorkPath,
    scoreList,
  } = props;
  const [praiseRoleQuestionnaireAdvice, setPraiseRoleQuestionnaireAdvice] =
    useState(initialPraiseRoleQuestionnaireAdvice);
  const [adviceRoleQuestionnaireAdvice, setAdviceRoleQuestionnaireAdvice] =
    useState(initialAdviceRoleQuestionnaireAdvice);
  const [allowAdviceCreation, setAllowAdviceCreation] = useState<boolean>(
    initialPraiseRoleQuestionnaireAdvice.body !== null &&
      initialAdviceRoleQuestionnaireAdvice.body === null
  );
  const [historyUpdated, setHistoryUpdated] = useState<boolean>(false);
  const [errorModalMode, setErrorModalMode] = useState<
    | "due_to_excess_count_forced_exit_type"
    | "due_to_excess_length_forced_exit_type"
  >();
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (
      praiseRoleQuestionnaireAdvice.body === null ||
      adviceRoleQuestionnaireAdvice.body === null
    ) {
      timeoutId = setTimeout(() => {
        scrollToBottomOfChatContent();
      }, 100);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [praiseRoleQuestionnaireAdvice, adviceRoleQuestionnaireAdvice]);

  return (
    <div className="chat-screen__container">
      {errorModalMode !== undefined && (
        <BaseModal
          show={true}
          contentLabel="SuggestNewWorkModal"
          title="エラーが発生しました"
          onCloseModal={() => {
            setErrorModalMode(undefined);
          }}
        >
          <BaseModal.Body>
            <>
              {errorModalMode === "due_to_excess_count_forced_exit_type" && (
                <div className="text-center">
                  会話が想定回数を超えているため、
                  <br />
                  申し訳ございませんが初めからワークをやり直してください。
                </div>
              )}
              {errorModalMode === "due_to_excess_length_forced_exit_type" && (
                <div className="text-center">
                  大変申し訳ございません。ハッピーAIが想定外の動きをしてしまいました。
                  <br />
                  画面をリロードして再度お試しください。
                </div>
              )}
            </>
            <div className="d-flex justify-content-center mt-3">
              <Button
                title="画面を更新"
                className="btn button__light"
                onClick={() => {
                  window.location.reload();
                }}
              />
            </div>
          </BaseModal.Body>
        </BaseModal>
      )}
      <ChatScreenMenuArea
        questionnaire={questionnaire}
        questionnaires={questionnaires}
      />
      <div className="chat-screen__chat-container">
        <div className="chat-screen__chat-title-container">
          ハッピーAIからのメッセージ・アドバイス
        </div>
        <div
          id="chatContentArea"
          className="py-5 chat-screen__message-advice-chat-content-container"
        >
          {praiseRoleQuestionnaireAdvice.body !== null && (
            <>
              <Chat
                body={praiseRoleQuestionnaireAdvice.body}
                title="ハッピーAIからのメッセージ"
              />
            </>
          )}
          {adviceRoleQuestionnaireAdvice.body !== null && (
            <>
              <Chat
                body={adviceRoleQuestionnaireAdvice.body}
                title="ハッピーAIからのアドバイス"
              />
            </>
          )}
          {praiseRoleQuestionnaireAdvice.body === null && (
            <ChatInProgress
              role="praise"
              title="ハッピーAIからのメッセージ"
              questionnaire={questionnaire}
              questionnaireAdvice={praiseRoleQuestionnaireAdvice}
              lambdaFunctionUrlDomain={lambdaFunctionUrlDomain}
              awsAccessRoleName={awsAccessRoleName}
              highHappinessElements={highHappinessElements}
              lowHappinessElements={lowHappinessElements}
              raisedHappinessElements={raisedHappinessElements}
              scoreList={scoreList}
              onChange={() => {
                scrollToBottomOfChatContent();
              }}
              onFinish={(qa) => {
                setPraiseRoleQuestionnaireAdvice(qa);
              }}
              afterRegisted={() => {
                setHistoryUpdated(true);
              }}
              onExcessLengthError={() => {
                setErrorModalMode("due_to_excess_length_forced_exit_type");
              }}
            />
          )}
          {praiseRoleQuestionnaireAdvice.body !== null &&
            adviceRoleQuestionnaireAdvice.body === null && (
              <>
                {!allowAdviceCreation && (
                  <div className="d-flex justify-content-center">
                    <div>
                      <Button
                        title="続けてアドバイスを受け取る"
                        className="btn button__primary"
                        onClick={() => setAllowAdviceCreation(true)}
                      />
                    </div>
                  </div>
                )}
                {allowAdviceCreation && (
                  <ChatInProgress
                    role="advice"
                    title="ハッピーAIからのアドバイス"
                    questionnaire={questionnaire}
                    questionnaireAdvice={adviceRoleQuestionnaireAdvice}
                    lambdaFunctionUrlDomain={lambdaFunctionUrlDomain}
                    awsAccessRoleName={awsAccessRoleName}
                    highHappinessElements={highHappinessElements}
                    lowHappinessElements={lowHappinessElements}
                    raisedHappinessElements={raisedHappinessElements}
                    scoreList={scoreList}
                    onChange={() => {
                      scrollToBottomOfChatContent();
                    }}
                    onFinish={(qa) => {
                      setAdviceRoleQuestionnaireAdvice(qa);
                    }}
                    afterRegisted={() => {
                      setHistoryUpdated(true);
                    }}
                    onExcessLengthError={() => {
                      setErrorModalMode(
                        "due_to_excess_length_forced_exit_type"
                      );
                    }}
                  />
                )}
              </>
            )}
        </div>
        <div className="chat-screen__chat-input-container">
          <div className="p-3 mb-md-4 text-center color__gray">
            {
              <div>
                <div className="row p-md-2">
                  <div className="col-6 py-1">
                    <a href={interactiveHappinessAdvicePath}>
                      <Button className="btn button__highlignt font__size-14 width__100">
                        <div>
                          ハッピーAIに
                          <br />
                          相談する
                        </div>
                      </Button>
                    </a>
                  </div>
                  <div className="col-6 py-1">
                    <a href={interactiveWorkPath}>
                      <Button className="btn button__highlignt font__size-14 width__100">
                        <div>
                          ハッピーAIと
                          <br />
                          対話ワークを行う
                        </div>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}
